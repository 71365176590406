import React, { Suspense } from 'react';
const Spline = React.lazy(() => import('@splinetool/react-spline'));

function App() {
  function onLoad(e) {
    // If disposed is true, then it has not loaded yet. Or not rendered, at least.
    if (!e.disposed) {
      window.parent.postMessage({ type: 'spline-loaded' }, '*');
    }
  }

  return (
    <Suspense>
      <Spline
        scene="./scene.splinecode"
        onLoad={onLoad}
      />
    </Suspense>
  );
}

export default App;
